import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { addRole, updateRole } from "api/layer";
import { AppAction, AppContext } from "helpers/context";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import PermissionConfig from "./permission-config/permission-config";
import { RoleType as FormData } from "../type";

interface Props {
	open: boolean;
	onClose: () => void;
	onSave: () => void;
	data?: FormData;
}

function RoleEditModal(props: Props) {
	const [t] = useTranslation();
	const [context, dispatch] = useContext(AppContext);

	const [formData, setFormData] = useState<FormData>(
		props.data
			? props.data
			: {
					id: "",
					roleCode: "",
					roleName: "",
					parentId: null,
					roleLevel: "1",
					roleType: "1",
					status: "1",
					roleDesc: "",
			  }
	);

	const handleChange = (event: any) => {
		const { name, value, type, checked } = event.target;
		console.log("handleChange", name, value, type, checked);

		setFormData((prevData) => ({ ...prevData, [name]: value }));
	};

	const close = () => {
		props.onClose();
	};

	const handleSave = () => {
		let data = { ...formData };

		if (props.data) {
			updateRole(data)
				.then((res: any) => {
					if (res && res.code === 200) {
						props.onClose();
						dispatch({
							type: AppAction.setSnackBarParams,
							payload: {
								title: "editTenant",

								message: "editTenantSuccess",
							},
						});
					} else {
						dispatch({
							type: AppAction.setSnackBarParams,
							payload: {
								title: "editTenant",
								// text: "ccc",
								titleIconColor: "yellow",
								titleIcon: "warning",
								resMessage: res.message,
							},
						});
					}
				})
				.finally(() => {
					props.onSave();
				});
		} else {
			addRole(data)
				.then((res: any) => {
					if (res && res.code === 200) {
						props.onClose();
						dispatch({
							type: AppAction.setSnackBarParams,
							payload: {
								title: "addRoleSuccess",

								// message: "moveText",
							},
						});
					} else {
						dispatch({
							type: AppAction.setSnackBarParams,
							payload: {
								title: "addRole",
								// text: "ccc",
								titleIconColor: "yellow",
								titleIcon: "warning",
								resMessage: res.message,
							},
						});
					}
				})
				.finally(() => {
					props.onSave();
				});
		}
	};

	const reset = () => {
		setFormData(
			props.data
				? props.data
				: {
						id: "",
						roleCode: "",
						roleName: "",
						parentId: null,
						roleLevel: "1",
						roleType: "1",
						status: "1",
						roleDesc: "",
				  }
		);
	};

	return (
		<Dialog
			open={props.open}
			onClose={close}
		>
			<DialogTitle
				sx={{
					fontWeight: 600,
					fontSize: "16px",
					opacity: 0.79,
					color: "#333",
					width: "600px",
				}}
			>
				{props.data ? t("userSystem.editRole") : t("userSystem.addRole")}
			</DialogTitle>
			<DialogContent
				sx={{
					overflowY: "auto",
					"&::-webkit-scrollbar": { width: "8px" },
					"&::-webkit-scrollbar-thumb": { backgroundColor: "#888", borderRadius: "4px" },
					"&::-webkit-scrollbar-thumb:hover": { backgroundColor: "#555" },
					"&::-webkit-scrollbar-track": { background: "#f1f1f1" },
				}}
			>
				<div>
					<TextField
						label={t("userSystem.roleName")}
						variant="outlined"
						fullWidth
						margin="normal"
						name="roleName"
						color="success"
						value={formData.roleName}
						onChange={handleChange}
						size="small"
					/>
					<TextField
						label={t("userSystem.roleDesc")}
						variant="outlined"
						fullWidth
						margin="normal"
						name="roleDesc"
						color="success"
						value={formData.roleDesc}
						onChange={handleChange}
						size="small"
					/>
				</div>

				{/* <PermissionConfig roleId={formData.id} /> */}
			</DialogContent>
			<DialogActions style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "16px 24px" }}>
				<Button
					onClick={reset}
					color="success"
				>
					{t("analyseModal.buttons.reset")}
				</Button>

				<div style={{ marginLeft: "auto" }}>
					<Button
						onClick={close}
						color="success"
					>
						{t("locations.modal.cancel")}
					</Button>
					<Button
						onClick={handleSave}
						color="success"
						variant="contained"
					>
						{t("locations.add.save")}
					</Button>
				</div>
			</DialogActions>
		</Dialog>
	);
}

export default RoleEditModal;
