import { Button } from "@mui/material";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { deleteLocationsets, patchLocationsets } from "api/layer";
import Icon from "components/icon/icon";
import { LeftPanel } from "helpers/constants";
import { AppAction, AppContext, setLeftPanel } from "helpers/context";
import { LocationData } from "helpers/models";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./edit-location-set.module.scss";

export default function EditLocationSet() {
	const [context, dispatch] = useContext(AppContext);
	const [t] = useTranslation();
	// const [currentMultipleLocations, setCurrentMultipleLocations] = useState(context.currentMultipleLocations!);
	// const options: locationSourceOption[] = [{ label: "Latitude/Longitude", value: "latlng" }];
	// const [selectValue, setSelectValue] = useState<locationSourceOption | null>(options[0]);

	const [isError, setIsError] = useState(false);
	const currentMultipleLocations = context.currentMultipleLocations;
	const [inputValue, setInputValue] = useState(currentMultipleLocations?.name || "");

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.value) {
			setIsError(false);
		} else {
			setIsError(true);
		}
		setInputValue(e.target.value);
	};

	const save = () => {
		if (!inputValue) {
			return;
		}
		if (!currentMultipleLocations) {
			return;
		}
		patchLocationsets(currentMultipleLocations.id, { ...currentMultipleLocations, name: inputValue }).then((res: any) => {
			if (res.code !== 200 || !res.data) {
				return;
			}
			const name = res.data.name;
			let multipleLocations = context.multipleLocations?.map((locationSet: LocationData) => {
				if (locationSet.id === currentMultipleLocations.id) {
					locationSet = { ...locationSet, name };
				}
				return locationSet;
			});
			dispatch({ type: AppAction.setCurrentMultipleLocations, payload: { ...context.currentMultipleLocations, name } });
			dispatch({ type: AppAction.setMultipleLocations, payload: multipleLocations });
			dispatch({
				type: AppAction.setSnackBarParams,
				payload: {
					title: "editLocationSetTitle",
					text: inputValue,
					message1: "editLocationSetMessage1",
					message2: "editLocationSetMessage2",
				},
			});
			setLeftPanel(dispatch, LeftPanel.Locations);
		});
	};

	const deleteSet = () => {
		dispatch({
			type: AppAction.setDeleteLocationSetModal,
			payload: context.currentMultipleLocations,
		});
	};

	const closeEdit = (): void => {
		setLeftPanel(dispatch, LeftPanel.Locations);
	};

	return (
		<div className={styles.edit}>
			<div className={styles.header}>
				<div className={styles.title}>
					<div className="flex items-center">
						<Icon
							icon="arrow_back"
							onClick={closeEdit}
						/>
						<span>{t("locationSet.edit.title")}</span>
					</div>
					<div>
						<Icon onClick={() => setLeftPanel(dispatch, LeftPanel.Empty)} />
					</div>
				</div>
			</div>
			<div className={styles.body}>
				<Stack spacing={{ xs: 1, sm: 2 }}>
					<TextField
						required
						sx={{ width: 340 }}
						// value={currentMultipleLocations.name}
						// onChange={(e) => setCurrentMultipleLocations({ ...currentMultipleLocations, name: e.target.value })}
						label={t("locationSet.edit.Location_set_name")}
						variant="outlined"
						color="success"
						value={inputValue}
						onChange={handleChange}
						onBlur={(e) => {
							if (e.target.value) {
								setIsError(false);
							} else {
								setIsError(true);
							}
						}}
						onFocus={() => {
							setIsError(false);
						}}
						error={isError}
						helperText={isError && t("locations.editModal.nameText")}
					/>
					{/* <div>{t("locationSet.edit.Location_display_options")}</div>
					<Autocomplete
						disablePortal
						value={selectValue}
						onChange={(event, newValue) => {
							setSelectValue(newValue);
						}}
						id="controllable-states-demo"
						options={options}
						renderInput={(params) => (
							<TextField
								required
								{...params}
								label={t("locations.createModal.text2")}
							/>
						)}
					/> */}
				</Stack>
			</div>
			<div className={styles.footer}>
				<div>
					<Button
						variant="contained"
						onClick={save}
						color="success"
						style={{marginRight: "10px"}}
					>
						{t("locationSet.edit.save")}
					</Button>
					<Button
						variant="text"
						onClick={closeEdit}
						color="success"
					>
						{t("locationSet.edit.cancel")}
					</Button>
				</div>
				<Button
					variant="text"
					onClick={deleteSet}
					color="error"
				>
					{t("locationSet.edit.deleteSet")}
				</Button>
			</div>
		</div>
	);
}
