
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Menu from "@mui/material/Menu";
import * as turf from '@turf/turf';
import { getLegend, layerStatistics } from "api/layer";
import china from "assets/json/c1.json";
import Icon from "components/icon/icon";
import { AppAction, AppContext } from "helpers/context";
import { MapContext } from "helpers/map";
import { Children, MapLayer } from "helpers/models";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Expander from "../expander/expander";
import styles from "./layerList.module.scss";
import Slider from '@mui/material/Slider';

let oversea = true;
let oversea1 = true;
let chinaGeojson = turf.polygon(china.features[0].geometry.rings);

interface Props {
    inputFilter: string;
}

function Layers(props: Props) {
    const [context, dispatch] = useContext(AppContext);
    const { AMap, map } = useContext(MapContext);
    const [t] = useTranslation();
    const [layerList, setLayerList] = useState<MapLayer[]>(context.layerList || []);
    // const [oversea, setOversea] = useState(false);
    const [updateView, setUpdateView] = useState("false");
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [iconCurrent, setIconCurrent] = useState<any>(null);

    useEffect(() => {
        setLayerList(context.layerList || [])
    }, [context.layerList])

    useEffect(() => {
        map?.on(["complete", "moveend", "zoomend"], () => {
            var center = map.getCenter();
            let point = [center.lng, center.lat];
            let zoom = map.getZoom();
            let isChina = turf.booleanPointInPolygon(point, chinaGeojson)
            console.log(zoom);

            if (isChina && oversea1 && zoom > 11.63) {
                oversea1 = false;
                handleChangLayerData1(false)
            } else if ((!isChina || zoom <= 11.63) && !oversea1) {
                oversea1 = true;
                handleChangLayerData1(true)
            }

            if (isChina && oversea && zoom >= 7) {
                oversea = false;
                handleChangLayerData(false)
            } else if ((!isChina || zoom < 7) && !oversea) {
                oversea = true;
                handleChangLayerData(true)
            }
        });
    }, [map, layerList])

    const handleChangLayerData = (is: boolean) => {
        layerList.forEach(item => {
            item.children.map(v => {
                if (v.checked) {
                    if (v.id === "EQ_Tsunami_SwissRe") {
                        v.layerObjEn.show()
                        v.layerObjCn.hide()
                    } else if (v.id !== "WS_Windspeed_Global_SwissRe" && v.id !== "CS_Lightning_Global_SwissRe") {
                        if (is) {
                            v.layerObjEn.show()
                            v.layerObjCn.hide()
                        } else {
                            v.layerObjEn.hide()
                            v.layerObjCn.show()
                        }
                    }
                }
                return v;
            })
        });
    }

    const handleChangLayerData1 = (is: boolean) => {
        layerList.forEach(item => {
            item.children.map(v => {
                if (v.checked && (v.id === "WS_Windspeed_Global_SwissRe" || v.id === "CS_Lightning_Global_SwissRe")) {
                    if (is) {
                        v.layerObjEn.show()
                        v.layerObjCn.hide()
                    } else {
                        v.layerObjEn.hide()
                        v.layerObjCn.show()
                    }
                }
                return v;
            })
        });
    }

    const handleCheckLayer = async (layerInfo: Children) => {
        let layerLength = 0
        layerList.forEach(item => {
            layerLength += item.children.filter(v => v.checked).length
        });

        if (layerLength >= 5 && !layerInfo.checked) {
            dispatch({
                type: AppAction.setSnackBarParams, payload: {
                    title: "limitAlert",
                    titleIcon: "error",
                    titleIconColor: "#fff"
                }
            })
            // dispatch({
            //     type: AppAction.setLayerInfoModal,
            //     payload: {
            //         name: (<div style={{ display: "flex", alignItems: "center" }}><span className="material-icons" style={{ marginRight: "8px" }}>{"info"}</span>{t("layers.limitAlertTitle")} </div>),
            //         description: t("layers.limitAlert")
            //     },
            // });
            return;
        }

        layerInfo.checked = !layerInfo.checked;
        setUpdateView(layerInfo.checked + '')
        if (layerInfo.checked) {
            layerStatistics({
                eventType: "risklayer",
                perils: layerInfo.id,
                layerAmount: 1,
                status: "1",
                flag: "success",
            })
            if (layerInfo.layerObjCn) {
                if (layerInfo.id === "EQ_Tsunami_SwissRe") {
                    layerInfo.layerObjEn.show()
                } else if (layerInfo.id === "WS_Windspeed_Global_SwissRe" || layerInfo.id === "CS_Lightning_Global_SwissRe") {
                    if (oversea1) {
                        layerInfo.layerObjEn.show()
                        layerInfo.layerObjCn.hide()
                    } else {
                        layerInfo.layerObjEn.hide()
                        layerInfo.layerObjCn.show()
                    }
                } else {
                    if (oversea) {
                        layerInfo.layerObjEn.show()
                        layerInfo.layerObjCn.hide()
                    } else {
                        layerInfo.layerObjEn.hide()
                        layerInfo.layerObjCn.show()
                    }
                }
                dispatch({ type: AppAction.updateLayerLegend, payload: layerInfo });
            } else {
                // var wms = new AMap.TileLayer({
                // 	tileUrl: `${process.env.REACT_APP_AMAP_LAYER_URL}/risklayer-api/proxy/catnet/tiles/${layerInfo.id}/tile/[z]/[x]/[y]?X-Token=${sessionStorage.getItem("token")}`,
                // 	opacity: 0.5,
                // });
                // layerInfo.layerObj = wms;
                // map.addLayer(wms);
                var layerObjEn = new AMap.TileLayer({
                    tileUrl: `${(window as any).amapLayerUrl}/risklayer-api/catnet-cn/tilesEx/${layerInfo.id}/tile/[z]/[x]/[y]?X-Token=${sessionStorage.getItem("token")}&oversea=true`,
                    opacity: layerInfo.opacity,
                });
                var layerObjCn = new AMap.TileLayer({
                    tileUrl: `${(window as any).amapLayerUrl}/risklayer-api/catnet-cn/tilesEx/${layerInfo.id}/tile/[z]/[y]/[x]?X-Token=${sessionStorage.getItem("token")}&oversea=false`,
                    opacity: layerInfo.opacity,
                });
                layerInfo.layerObjEn = layerObjEn;
                layerInfo.layerObjCn = layerObjCn;
                map.addLayer(layerObjEn);
                map.addLayer(layerObjCn);
                if (layerInfo.id === "EQ_Tsunami_SwissRe") {
                    layerInfo.layerObjCn.hide(0)
                } else if (layerInfo.id === "WS_Windspeed_Global_SwissRe" || layerInfo.id === "CS_Lightning_Global_SwissRe") {
                    if (oversea1) {
                        layerInfo.layerObjCn.hide(0)
                    } else {
                        layerInfo.layerObjEn.hide(0)
                    }
                } else {
                    if (oversea) {
                        layerInfo.layerObjCn.hide(0)
                    } else {
                        layerInfo.layerObjEn.hide(0)
                    }
                }
                await getLegend(layerInfo.id).then(async (res: any) => {
                    dispatch({ type: AppAction.addLayerLegend, payload: { ...res.data, layerInfo } })
                });
            }
        } else {
            layerInfo.layerObjEn.hide(0)
            layerInfo.layerObjCn.hide(0)
            dispatch({ type: AppAction.updateLayerLegend, payload: layerInfo });
        }
    };

    const handleChangeOpacity = (event: any, value: any) => {
        let layerInfo = { ...iconCurrent, opacity: value };
        let list = layerList!.map((layer) => {
            if (layer.name === layerInfo.group) {
                layer.children.map((layerItem) => {
                    if (layerItem.id === layerInfo.id) {
                        layerItem.opacity = value;
                        layerItem.layerObjEn && layerItem.layerObjEn.setOpacity(value);
                        layerItem.layerObjCn && layerItem.layerObjCn.setOpacity(value);
                    }
                    return layerItem;
                })
            }
            return layer;
        });
        dispatch({ type: AppAction.setLayerList, payload: list });
        setLayerList(list);
        setIconCurrent(layerInfo);
    }

    const toggleLayerInfo = (layerInfo: Children) => {
        dispatch({
            type: AppAction.setLayerInfoModal,
            payload: layerInfo,
        });
    };

    interface DebounceFunction<T extends (...args: any[]) => void> {
        (this: unknown, ...args: Parameters<T>): void;
    }

    //防抖 N秒内重复触发的话只触发最后一次
    function debounce<T extends (...args: any[]) => void>(fn: T, delay: number, immediate = false): DebounceFunction<T> {
        let timer: NodeJS.Timeout | null = null;
        let isInvoke = false;

        const _debounce: DebounceFunction<T> = function (this: unknown, ...args: Parameters<T>): void {
            if (timer) clearTimeout(timer);

            if (immediate && !isInvoke) {
                fn.apply(this, args);
                isInvoke = true;
            } else {
                timer = setTimeout(() => {
                    fn.apply(this, args);
                    isInvoke = false;
                }, delay);
            }
        };

        return _debounce;
    }

    return (
        <div className={styles.perils}>
            {(layerList.length > 0) &&
                layerList.map((layer, index) => (
                    (layer.children.filter(v => (t("layers.item." + v.name).toLowerCase()).indexOf(props.inputFilter.toLowerCase()) > -1).length) > 0 && (
                        <Expander
                            key={index}
                            title={t("layers.group." + layer.name)}
                            count={layer.children.length}
                        >
                            {layer.children.map((item, i) => (
                                (t("layers.item." + item.name).toLowerCase()).indexOf(props.inputFilter.toLowerCase()) > -1 &&
                                (<div
                                    // className={
                                    //   !item.onClick ? styles.checkboxDisabled : styles.checkbox
                                    // }
                                    key={i}
                                    className={styles.checkbox}
                                    style={{ backgroundColor: iconCurrent && (iconCurrent.id === item.id) ? "var(--gray-hover)" : "" }}
                                >
                                    <FormGroup
                                        onChange={() => handleCheckLayer(item)}
                                        sx={{
                                            flex: "1"
                                        }}
                                    >
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={item.checked}
                                                    color="success" />
                                            }
                                            label={(<span className='text-[13px] font-normal'>{t("layers.item." + item.name)}</span>)} />
                                    </FormGroup>
                                    <div
                                        className={styles.icons}
                                        onClick={(evt) => evt.stopPropagation()}
                                        style={{ display: iconCurrent && (iconCurrent.id === item.id) ? "flex" : "" }}
                                    >
                                        <Icon
                                            icon="info"
                                            onClick={() =>
                                                toggleLayerInfo({
                                                    name: t("layers.item." + item.name),
                                                    id: item.id,
                                                    group: item.group,
                                                    category: item.category,
                                                    description: t("layers.description." + item.name),
                                                })
                                            }
                                        />

                                        <Icon
                                            icon="opacity"
                                            onClick={(event) => {
                                                setIconCurrent(item)
                                                setAnchorEl(event.currentTarget);
                                            }}
                                        />
                                    </div>
                                </div>
                                )))}
                        </Expander>)
                ))}
            {iconCurrent && <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => {
                    setIconCurrent(null);
                    setAnchorEl(null);
                }}
            >
                <div className="px-4 py-2 w-[200px]">
                    <div className="text-[14px] my-[4px]">{t("layers.opacity")}: {(iconCurrent.opacity * 100).toFixed(0)}</div>
                    <Slider
                        value={iconCurrent.opacity}
                        size="small"
                        max={1}
                        min={0}
                        step={0.01}
                        color="success"
                        onChange={handleChangeOpacity}
                    // sx={{
                    //     "& .MuiSlider-thumb:hover": {
                    //         boxShadow: "none",
                    //         // transform: "scale(1)",
                    //     }
                    // }}
                    />
                </div>
            </Menu>}
        </div>
    );
}

export default Layers;
