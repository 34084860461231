import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { ReactComponent as IconTable } from "assets/icon_table_th.svg";
import { ReactComponent as NotAnalysisSvg } from "assets/notAnalysis.svg";
import Chart from "components/chart/chart";
import Svg from "components/svg/svg";
import { AppContext } from "helpers/context";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./hazard-multiple-results.module.scss";

export default function HazardMultipleResults() {
	const [context, dispatch] = useContext(AppContext);
	const [t] = useTranslation();
	const [activeLegend, setActiveLegend] = useState(0);
	const [chartOption, setChartOption] = useState<any>(undefined);
	const [chartHeight, setChartHeight] = useState(0);
	const [tableDatas, setTableDatas] = useState<any[]>([]);
	const [noData, setNoData] = useState<any[]>([]);
	// const [analysisResultData, setAnalysisResultData] = useState<any[]>([]);
	const [sortData, setSortData] = useState<any[]>([]);
	const [active, setActive] = useState<"intensity" | "value" | "share">("intensity");
	const [direction, setDirection] = useState<{ intensity: "asc" | "desc"; value: "asc" | "desc"; share: "asc" | "desc" }>({ intensity: "asc", value: "asc", share: "asc" });

	const handleSort = (property: "intensity" | "value" | "share") => {
		if (!tableDatas.length) {
			return;
		}

		if (!property) {
			return;
		}

		setActive(property);
		direction[property] = direction[property] === "asc" ? "desc" : "asc";
		setDirection(direction);

		handleSetSortData(JSON.parse(JSON.stringify(tableDatas)), property);
	};

	const handleSetSortData = (tableData: any, property = active, activIndex = activeLegend) => {
		console.log(property);

		if (!tableData || !tableData.length) return;
		let sortArr = [...tableData];
		if (sortArr.length === 0) return;
		let key = property;
		if (key === "share") {
			key = "value";
		}
		sortArr[activIndex].sort((a: any, b: any) => {
			if (!a || !b) {
				return 0;
			}
			const aValue = a?.[key];
			const bValue = b?.[key];
			if (aValue === undefined) {
				return direction[property] === "asc" ? -1 : 1;
			}
			if (bValue === undefined) {
				return direction[property] === "asc" ? 1 : -1;
			}
			if (aValue < bValue) {
				return direction[property] === "asc" ? 1 : -1;
			}
			if (aValue > bValue) {
				return direction[property] === "asc" ? -1 : 1;
			}
			return 0;
		});
		setSortData([...sortArr]);
	};

	const handleLegendClick = (index: number) => {
		setActiveLegend(index);
		handleSetSortData(JSON.parse(JSON.stringify(tableDatas)), active, index);
	};

	const toFixedTwo = (num: number) => {
		return num % 1 === 0 ? num : num.toFixed(2);
	};

	const noDataText = () => {
		if (noData.length > 0) {
			let text = t("multiple.noData");
			noData.forEach((item: any, index: number) => {
				if (index === 0) {
					text += t(`layerId.${item.layerId}`);
				} else if (index === noData.length - 1) {
					text += t("multiple.and") + t(`layerId.${item.layerId}`);
				} else {
					text += t("multiple.comma") + t(`layerId.${item.layerId}`);
				}
			});
			return text + t("multiple.period");
		}
	};

	useEffect(() => {
		if (context.hazardMultipleAnalysisResult && context.hazardMultipleAnalysisResult.length > 0
			&& context.hazardMultipleAnalysedLegends && context.hazardMultipleAnalysedLegends.length > 0
			&& context.hazardMultipleAnalysisStatus !== "error" && context.hazardMultipleAnalysisStatus !== "running") {
			setActiveLegend(0);
			let resultData = context.hazardMultipleAnalysisResult.filter((item: any) => {
				let isFind = context.hazardMultipleAnalysedLegends!.find((legend: any) => {
					return legend.layerId === item.layerId;
				});
				if (item.layerId === "WF_DistToBush_AUS_SwissRe") {
					if (item.valueLabel === "< 25") {
						item.intensity = 1;
					} else if (item.valueLabel === "25 to 50") {
						item.intensity = 2;
					} else if (item.valueLabel === "50 to 75") {
						item.intensity = 3;
					} else if (item.valueLabel === "75 to 100") {
						item.intensity = 4;
					} else if (item.valueLabel === "100 to 300") {
						item.intensity = 5;
					} else if (item.valueLabel === "300 to 500") {
						item.intensity = 6;
					} else if (item.valueLabel === "> 500") {
						item.intensity = 7;
					}
				} else if (item.layerId === "DR_Subsidence_France_SwissRe") {
					if (item.valueLabel === "High") {
						item.intensity = 1;
					} else if (item.valueLabel === "Medium") {
						item.intensity = 2;
					} else if (item.valueLabel === "Low") {
						item.intensity = 3;
					} else if (item.valueLabel === "Very Low") {
						item.intensity = 4;
					}
				}

				if (item.valueLabel === "Negligible (< 0.014)") {
					item.intensity = 0.5;
				}

				if (item.valueLabel === "Low (25-30 m/s)") {
					item.intensity = 1;
				} else if (item.valueLabel === "Low (20-25 m/s)") {
					item.intensity = 0.5;
				} else if (item.valueLabel === "Very Low (<20 m/s)") {
					item.intensity = 0.1;
				}

				if (item.valueLabel === "Outside" || item.valueLabel === "No Data" || item.valueLabel === "No Observation" || item.valueLabel === "Negligible") {
					item.intensity = 0;
				}
				return isFind !== undefined;
			});
			console.log(resultData);

			// setAnalysisResultData(resultData)
			let arr: any = [...resultData].sort((a: any, b: any) => {
				return b.intensity - a.intensity;
			});


			let data: any = [];

			context.hazardMultipleAnalysedLegends.forEach((legend, index) => {
				arr.forEach((item: any) => {
					if (item.layerId === legend.layerId) {
						if (item.valueLabel === "Outside" || item.valueLabel === "No Data" || item.valueLabel === "No Observation" || item.valueLabel === "Negligible" || item.valueLabel === undefined) {
							if (!item.valueLabel) {
								item.valueLabel = "No Data";
							}
							if (data[index]) {
								data[index].push({
									...legend,
									...item,
									value: 1,
									itemStyle: {
										color: "grey",
										opacity: "0.5",
									},
								});
							} else {
								data[index] = [
									{
										...legend,
										...item,
										value: 1,
										itemStyle: {
											color: "grey",
											opacity: "0.5",
										},
									},
								];
							}
						} else {
							legend.rules.forEach((rules: any) => {
								if (item.valueLabel === rules.title) {
									const raster = rules.symbolizers[0]?.raster?.color;
									const polygon = rules.symbolizers[0]?.polygon?.fill;
									const rasterOpacity = rules.symbolizers[0]?.raster?.opacity;
									const polygonOpacity = rules.symbolizers[0]?.polygon?.fillOpacity;
									const color = raster || polygon;
									const opacity = rasterOpacity || polygonOpacity;
									if (data[index]) {
										data[index].push({
											...legend,
											...item,
											value: 1,
											itemStyle: {
												color: color,
												opacity: opacity,
											},
										});
									} else {
										data[index] = [
											{
												...legend,
												...item,
												value: 1,
												itemStyle: {
													color: color,
													opacity: opacity,
												},
											},
										];
									}
								}
							});
						}
					}
				});
			});

			let tableData: any = [],
				noData: any = [];

			data.forEach((dataItem: any) => {
				const valueMap: any = {};
				if (dataItem && dataItem.length > 0) {
					dataItem.forEach((item: any) => {
						const label = item.valueLabel;
						if (valueMap[label] === undefined) {
							valueMap[label] = 1; // First occurrence
							item.value = dataItem.filter((i: any) => i.valueLabel === label).length; // Count total occurrences
						} else {
							item.value = 0; // Subsequent occurrences
						}
					});

					if ((dataItem[0].valueLabel === "Outside" || dataItem[0].valueLabel === "No Data" || dataItem[0].valueLabel === "No Observation" || dataItem[0].valueLabel === "Negligible") && dataItem[0].value === dataItem.length) {
						noData.push(dataItem[0]);
					} else {
						tableData.push(dataItem);
					}
				}
			});

			setTableDatas(tableData);
			setNoData(noData);
			handleSetSortData(JSON.parse(JSON.stringify(tableData)), active, 0);

			let series: any = [];
			let Xnum = tableData[0] ? tableData[0].length : 0;
			let Ynum = tableData.length;
			setChartHeight(Ynum);
			for (let i = 0; i < Xnum; i++) {
				let dataArr = [];
				for (let j = 0; j < Ynum; j++) {
					if (tableData[j]) {
						dataArr.unshift(tableData[j][i]);
					}
				}
				series.push({
					name: i,
					type: "bar",
					stack: "total",
					barWidth: 24,
					// emphasis: {
					//     focus: 'self'
					// },
					data: dataArr,
				});
			}

			const option: any = {
				tooltip: {
					confine: true,
					trigger: "item",
					axisPointer: {
						type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
					},
					backgroundColor: "rgba(0,0,0,0.6)",
					borderColor: "rgba(0,0,0,0.6)",
					formatter: function (params: any, ticket: any) {
						return `<div>
                            <p style="color: #fff;font-size:14px;">${t("legend." + params.data.valueLabel)}</p>    
                            <p style="color: #fff;font-size:14px;margin: 5px 0;">${t("layerId." + params.data.layerId)}</p>    
                            <p style="display: flex;justify-content: space-between;align-items: center;font-size: 12px;"><span style="color: rgba(255,255,255,0.8);margin-right:10px;">${t(
							"locations.table.locations"
						)}</span> <span style="color: #fff">${params.data.value}</span></p>    
                            <p style="display: flex;justify-content: space-between;align-items: center;font-size: 12px;"><span style="color: rgba(255,255,255,0.8);margin-right:10px;">${t(
							"locations.shareOfNumberOfLocations"
						)}</span> <span style="color: #fff">${toFixedTwo((params.data.value / Xnum) * 100)}%</span></p>    
                        </div>`;
					},
				},
				grid: {
					left: 10,
					right: 10,
					bottom: 10,
					top: 10,
					containLabel: true,
				},
				xAxis: {
					type: "value",
					axisLine: {
						show: true,
					},
					minInterval: 1,
					max: Xnum,
				},
				yAxis: {
					type: "category",
					axisTick: {
						show: false,
					},
					axisLabel: {
						show: false,
					},
					axisLine: {
						show: true,
						onZero: true,
						lineStyle: {
							color: "#fff",
							shadowOffsetX: -1,
							shadowColor: "#333",
						},
					},
				},
				series: series,
			};

			setChartOption(option);
		}
	}, [context.hazardMultipleAnalysisResult, context.hazardMultipleAnalysedLegends]);

	if (context.hazardMultipleAnalysisStatus && context.hazardMultipleAnalysisStatus === "error") {
		return (
			<div className="h-full flex flex-col items-center justify-center">
				<div
					className="text-center"
					style={{ color: "gray" }}
				>
					<span className="material-icons">error_outline</span>
				</div>
				<div className="text-[14px]">{t("single.graunchTitle")}</div>
				<div dangerouslySetInnerHTML={{ __html: t("single.graunchContent") }}></div>
			</div>
		);
	}

	if (!context.hazardMultipleAnalysedLegends || context.hazardMultipleAnalysedLegends.length === 0) {
		return (
			<div className="h-full flex flex-col items-center justify-center">
				<NotAnalysisSvg className="w-[60px] h-[60px] mx-auto mb-4" />
				<div style={{ color: "var(--gray-text)" }}>{t("single.notMultipleAnalysis")}</div>
			</div>
		);
	}

	if (context.hazardMultipleAnalysisStatus && context.hazardMultipleAnalysisStatus === "running") {
		return (
			<div className="h-full flex flex-col items-center justify-center">
				<LinearProgress
					color="success"
					style={{ width: "40%", marginBottom: "10px" }}
				/>
				<div>{t("multiple.alalyseLoadingContent")}</div>
			</div>
		);
	}

	return (
		<>
			{tableDatas.length > 0 && (
				<>
					<div
						className={styles.chart}
						style={{ height: chartHeight * 63 + "px" }}
					>
						<div className={styles.legendIcon}>
							{tableDatas.map((tableDataItem: any, index: number) => {
								return (
									<IconButton
										key={index}
										onClick={() => {
											handleLegendClick(index);
										}}
										className={`${activeLegend === index ? styles.activeLegend : ""}`}
									>
										{Svg({ icon: tableDataItem[0].layerId })()}
									</IconButton>
								);
							})}
						</div>
						<Chart option={chartOption} />
					</div>
					<div className="text-center text-[12px]">{t("multiple.chartTitle")}</div>
				</>
			)}
			{noData.length > 0 && <div className="m-2">{noDataText()}</div>}
			{(tableDatas[activeLegend] || noData.length > 0) && (
				<div>
					<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingRight: "10px" }}>
						<p className="m-2 font-bold">{t(`layerId.${tableDatas[activeLegend] ? tableDatas[activeLegend][0].layerId : noData[0].layerId}`)}</p>
						<div style={{ padding: "6px 10px", borderRadius: "5px", backgroundColor: "var(--green-hover)", boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)" }}>
							<IconTable />
						</div>
					</div>
					<Paper
						sx={{ width: "100%", boxShadow: "none" }}
						className={styles.table}
					>
						<TableContainer sx={{ height: "100%" }}>
							<Table
								stickyHeader
								aria-label="sticky table"
								size="small"
							>
								<TableHead>
									<TableRow
										className={styles.row}
										sx={{
											"& th": {
												fontSize: "12px",
												color: "#000000de"
											}
										}}
									>
										<TableCell align="left" style={{ padding: "8px" }}>
											<TableSortLabel
												active={active === "intensity"}
												onClick={() => handleSort("intensity")}
												direction={direction.intensity}
											>
												{sortData[activeLegend]
													? sortData[activeLegend][0].subtitle
														? t("legend." + sortData[activeLegend][0].subtitle)
														: ""
													: noData[0].subtitle
														? t("legend." + noData[0].subtitle)
														: ""}
											</TableSortLabel>
										</TableCell>
										<TableCell align="right" style={{ width: "85px", padding: "8px" }}>
											<TableSortLabel
												active={active === "value"}
												onClick={() => handleSort("value")}
												direction={direction.value}
											>
												{t("locations.table.locations")}
											</TableSortLabel>
										</TableCell>
										<TableCell align="right" style={{ width: "85px", padding: "8px 16px 8px 8px" }}>
											<TableSortLabel
												active={active === "share"}
												onClick={() => handleSort("share")}
												direction={direction.share}
											>
												{t("locations.table.share")}
											</TableSortLabel>
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{sortData[activeLegend] &&
										sortData[activeLegend].map(
											(item: any, index: number) =>
												item.value > 0 && (
													<TableRow
														key={index}
														sx={{
															"& td": {
																fontSize: "13px",
																color: "#000000de"
															}
														}}
													>
														<TableCell align="left" style={{ padding: "8px" }}>
															<div style={{ display: "flex", alignItems: "center" }}>
																<span
																	style={{
																		flexShrink: 0,
																		width: "16px",
																		height: "16px",
																		marginRight: "5px",
																		display: "inline-block",
																		backgroundColor: item.itemStyle.color,
																		opacity: item.itemStyle.opacity,
																	}}
																></span>
																{item.valueLabel ? t("legend." + item.valueLabel) : ""}
															</div>
														</TableCell>
														<TableCell align="right" style={{ padding: "8px" }}>{item.value}</TableCell>
														<TableCell align="right" style={{ padding: "8px 16px 8px 8px" }}>{toFixedTwo((item.value / sortData[activeLegend].length) * 100)}%</TableCell>
													</TableRow>
												)
										)}
									<TableRow
										style={{ fontWeight: "bold", height: "50px" }}
										sx={{
											"& td": {
												fontSize: "13px",
												color: "#000000de"
											}
										}}
									>
										<TableCell style={{ padding: "8px" }}>{t("multiple.total")}</TableCell>
										<TableCell align="right" style={{ padding: "8px" }}>{sortData[activeLegend] ? sortData[activeLegend].length : 0}</TableCell>
										<TableCell align="right" style={{ padding: "8px 16px 8px 8px" }}>100%</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</TableContainer>
					</Paper>
				</div>
			)}
		</>
	);
}
