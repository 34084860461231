export const config: any = {
	drought: {
		"2030": "O",
		"2035": "P",
		"2040": "Q",
		"2045": "R",
		"2050": "S",
		"2055": "T",
		"2060": "U",
		"2070": "V",
		"2085": "W",
	},
	wet: {
		"2030": "Y",
		"2035": "Z",
		"2040": "AA",
		"2045": "AB",
		"2050": "AC",
		"2055": "AD",
		"2060": "AE",
		"2070": "AF",
		"2085": "AG",
	},
	boreal_winter_wet: {
		"2030": "AI",
		"2035": "AJ",
		"2040": "AK",
		"2045": "AL",
		"2050": "AM",
		"2055": "AN",
		"2060": "AO",
		"2070": "AP",
		"2085": "AQ",
	},
	boreal_summer_wet: {
		"2030": "AS",
		"2035": "AT",
		"2040": "AU",
		"2045": "AV",
		"2050": "AW",
		"2055": "AX",
		"2060": "AY",
		"2070": "AZ",
		"2085": "BA",
	},
	heat_wave: {
		"2030": "BC",
		"2035": "BD",
		"2040": "BE",
		"2045": "BF",
		"2050": "BG",
		"2055": "BH",
		"2060": "BI",
		"2070": "BJ",
		"2085": "BK",
	},
	wind: {
		"2030": "BM",
		"2035": "BN",
		"2040": "BO",
		"2045": "BP",
		"2050": "BQ",
		"2055": "BR",
		"2060": "BS",
		"2070": "BT",
		"2085": "BU",
	},
	sea_level_rise: {
		"2030": "BW",
		"2035": "BX",
		"2040": "BY",
		"2045": "BZ",
		"2050": "CA",
		"2055": "CB",
		"2060": "CC",
		"2070": "CD",
		"2085": "CE",
	},
};
