import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormGroup } from "@mui/material";
import { AppAction, AppContext } from "helpers/context";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getMenu, queryMenuIdsByRoleId, upgradeRoleMenuPermission } from "../../../../api/layer";
import { RoleType } from "../type";
import styles from "./role-permission-modal.module.scss";
import { buildTree } from "./util/build";
import { getChildIds } from "./util/Tree";
import { MenuItem } from "./util/type";
import { getLanguage } from "helpers/translations";

interface Props {
	onClose: () => void;
	data?: RoleType;
}

const RolePermissionModal: React.FC<Props> = (props) => {
	const [context, dispatch] = useContext(AppContext);

	const [t] = useTranslation();
	const language = getLanguage();

	const [menuData, setMenuData] = useState<MenuItem[]>([]);
	const [checkedIdArray, setCheckedIdArray] = useState<string[]>([]);
	const [loading, setLoading] = useState(false);

	const fetchMenuData = async () => {
		try {
			setLoading(true);
			const resp: any = await getMenu();
			if (resp.code !== 200) {
				return;
			}
			const data: MenuItem[] = resp.data.filter((e: any) => e.menuType !== "qt");
			if (!data || data.length === 0) {
				return;
			}
			setMenuData(data);
		} catch (error) {
			console.error("获取菜单数据时出错:", error);
		} finally {
			setLoading(false);
		}
	};

	const fetchCheckedIds = async () => {
		if (!props.data) {
			return;
		}

		const roleId = props.data.id;

		if (!roleId) {
			return;
		}

		try {
			setLoading(true);
			const resp: any = await queryMenuIdsByRoleId({ roleId });
			if (resp.code !== 200) {
				return;
			}
			const data: string[] = resp.data;
			// if (!data || data.length === 0) {
			// 	return;
			// }
			setCheckedIdArray(data);
		} catch (error) {
			console.error("获取角色权限时出错:", error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchMenuData();

		fetchCheckedIds();
	}, []);

	const treeData = buildTree(menuData);

	const handleCheck = (id: string) => {
		const item = menuData.find((item) => item.id === id);

		if (!item) {
			return;
		}

		const index = checkedIdArray.indexOf(id);

		const childIds = getChildIds(id, menuData);

		const parentId = item.parentId;

		if (index === -1) {
			if (childIds && childIds.length > 0) {
				// setCheckedIdArray([parentId, ...checkedIdArray, id, ...childIds]);

				setCheckedIdArrayWithLogic([parentId, ...checkedIdArray, id, ...childIds]);
				return;
			}

			setCheckedIdArray([parentId, ...checkedIdArray, id]);
			return;
		}

		if (childIds && childIds.length > 0) {
			const newCheckedIdArray = checkedIdArray.filter((item) => !childIds.includes(item) && item !== id);
			setCheckedIdArray(newCheckedIdArray);
			return;
		}

		const siblings = menuData.filter((item) => item.parentId === parentId && item.id !== id);
		const allSiblingsUnchecked = siblings.every((sibling) => !checkedIdArray.includes(sibling.id));
		if (allSiblingsUnchecked) {
			const index = checkedIdArray.indexOf(parentId);
			if (index !== -1) {
				const newCheckedIdArray = checkedIdArray.filter((item) => item !== id && item !== parentId);
				setCheckedIdArray(newCheckedIdArray);
				return;
			}
		}

		setCheckedIdArray([...checkedIdArray.slice(0, index), ...checkedIdArray.slice(index + 1)]);
	};

	const setCheckedIdArrayWithLogic = (idArray: string[]) => {
		const uniqueCheckedIdArray = Array.from(new Set([...idArray])).filter((item) => item !== null && item !== undefined && item !== "");

		setCheckedIdArray(uniqueCheckedIdArray);
	};

	const handleSave = () => {
		if (!props.data) {
			return;
		}

		const param = {
			roleId: props.data.id,
			menuIds: checkedIdArray,
		};

		upgradeRoleMenuPermission(param)
			.then((resp: any) => {
				if (!resp || resp.code !== 200) {
					dispatch({
						type: AppAction.setSnackBarParams,
						payload: {
							title: "updatePermission",
							titleIconColor: "yellow",
							titleIcon: "warning",
							resMessage: resp.message,
						},
					});
					return;
				}
				dispatch({
					type: AppAction.setSnackBarParams,
					payload: {
						title: "updatePermission",
						message: "updatePermissionSuccess",
					},
				});
			})
			.catch((error) => {
				console.log(error);
				dispatch({
					type: AppAction.setSnackBarParams,
					payload: {
						title: "updatePermission",
						titleIconColor: "yellow",
						titleIcon: "warning",
						message: "updatePermissionError",
					},
				});
			})
			.finally(() => {
				props.onClose();
			});
	};

	const renderTree = (nodes: MenuItem[]) => {
		return nodes.map((node) => (
			<FormGroup key={node.id}>
				<FormControlLabel
					control={
						<Checkbox
							color="success"
							indeterminate={checkedIdArray.includes(node.id) && node.children && node.children.some((child) => !checkedIdArray.includes(child.id))}
							checked={checkedIdArray.includes(node.id)}
							onChange={() => handleCheck(node.id)}
							name="viewLayer"
						/>
					}
					label={<span style={{ fontSize: "14px", color: "#666" }}>{language === "en" ? node.menuLabelEn : node.menuLabel}</span>}
				/>
				{node.children && node.children.length > 0 && (
					<div
						className="children-container"
						style={{ paddingLeft: 24 }}
					>
						{renderTree(node.children)}
					</div>
				)}
			</FormGroup>
		));
	};

	const reset = () => {
		fetchCheckedIds();
	};

	return (
		<Dialog
			open={!!props.data}
			onClose={() => props.onClose()}
		>
			<DialogTitle
				sx={{
					fontWeight: 600,
					fontSize: "16px",
					opacity: 0.79,
					color: "#333",
					width: "400px",
				}}
			>
				{props.data && props.data.roleName}
			</DialogTitle>
			<DialogContent
				sx={{
					overflowY: "auto",
					"&::-webkit-scrollbar": { width: "8px" },
					"&::-webkit-scrollbar-thumb": { backgroundColor: "#888", borderRadius: "4px" },
					"&::-webkit-scrollbar-thumb:hover": { backgroundColor: "#555" },
					"&::-webkit-scrollbar-track": { background: "#f1f1f1" },
				}}
			>
				{loading ? (
					<div className={styles.loading}>
						<div className="loading loading-spinner loading-lg"></div>
						<div>{t("layers.loading")}</div>
					</div>
				) : (
					renderTree(treeData)
				)}
			</DialogContent>
			<DialogActions style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "16px 24px" }}>
				<Button
					onClick={reset}
					color="success"
				>
					{t("analyseModal.buttons.reset")}
				</Button>

				<div style={{ marginLeft: "auto" }}>
					<Button
						onClick={() => props.onClose()}
						color="success"
					>
						{t("locations.modal.cancel")}
					</Button>
					<Button
						onClick={handleSave}
						color="success"
						variant="contained"
					>
						{t("locations.add.save")}
					</Button>
				</div>
			</DialogActions>
		</Dialog>
	);
};

export default RolePermissionModal;
