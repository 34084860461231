
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Menu from "@mui/material/Menu";
import Switch from '@mui/material/Switch';
import { useTranslation } from "react-i18next";

interface FilterColumnsProps {
    anchorEl: any;
    handleClose: any;
    handleApply: any;
}

function MapSettings(props: FilterColumnsProps) {
    const [t] = useTranslation();
    const open = Boolean(props.anchorEl);

    return <Menu
        anchorEl={props.anchorEl}
        open={open}
        onClose={props.handleClose}
        sx={{
            "& .MuiList-root": {
                py: 0,
            },
        }}
    >
        <div className="card compact dropdown-content p-0 shadow rounded-[4px] overflow-hidden">
            <div className="text-[16px] m-[16px] h-[19px] font-medium text-[#000000de]">{t("events.eventsDisplaySettings")}</div>
            <div className="p-[10px]">
                <FormGroup>
                    <FormControlLabel
                        sx={{
                            "& .MuiFormControlLabel-label": {
                                fontSize: "14px",
                                color: "#000000de"
                            }
                        }}
                        control={<Switch defaultChecked color="success" />}
                        label={t("events.enableFloodEvent")}
                    />
                    <FormControlLabel
                        sx={{
                            "& .MuiFormControlLabel-label": {
                                fontSize: "14px",
                                color: "#000000de"
                            }
                        }}
                        control={<Switch defaultChecked color="success" />}
                        label={t("events.showTrackForTropicalCyclones")}
                    />
                </FormGroup>


            </div>
        </div>
    </Menu>;
}
export default MapSettings;