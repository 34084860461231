import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { addLocationsets, getLocationsetsItems } from "api/layer";
import Button from "components/button/button";
import Modal from "components/modal/modal";
import { AppAction, AppContext } from "helpers/context";
import { locationSourceOption } from "helpers/models";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./create-modal.module.scss";
import { LeftPanel, RightPanel } from "helpers/constants";
import { getLanguage } from "helpers/translations";

function CreateModal() {
	const [context, dispatch] = useContext(AppContext);
	const [t] = useTranslation();
	const options: locationSourceOption[] = [{ label: "Latitude/Longitude", value: "latlng" }];
	const [selectValue, setSelectValue] = useState<locationSourceOption | null>(options[0]);
	const [isError, setIsError] = useState(false);
	const [inputValue, setInputValue] = useState("");

	const close = (): void => {
		dispatch({ type: AppAction.setCreateModal, payload: false });
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.value) {
			setIsError(false);
		} else {
			setIsError(true);
		}
		setInputValue(e.target.value);
	};

	const loadData = (): void => {
		let params = {
			name: inputValue,
			locationSource: selectValue!.value,
		};

		addLocationsets(params)
			.then(async (result: any) => {
				if (result.code && result.code === 400) {
					dispatch({
						type: AppAction.setSnackBarParams,
						payload: {
							title: "tip",
							titleIconColor: "red",
							titleIcon: "error",
							resMessage: getLanguage() === "en" ? result.data.messageEN : result.data.messageCN,
						},
					});

					dispatch({ type: AppAction.setSingleLocationAnalysed, payload: false });
					dispatch({ type: AppAction.setMultipleLocationsAnalysed, payload: false });
					return;
				}
				// await getMultipleLocations(dispatch);
				if (!result || !result.data || !result.data.id) {
					dispatch({
						type: AppAction.setSnackBarParams,
						payload: {
							title: "errorTitle",
							text: params.name,
							message: "errorMessage",
						},
					});
					close();
				}
				let res = await getLocationsetsItems(result.data.id);
				if (context.multipleLocations) {
					dispatch({ type: AppAction.setMultipleLocations, payload: [...context.multipleLocations, res.data] });
				} else {
					dispatch({ type: AppAction.setMultipleLocations, payload: [res.data] });
				}
				dispatch({ type: AppAction.setSingleLocation, payload: undefined });
				dispatch({ type: AppAction.setSingleLocationAnalysed, payload: undefined });
				dispatch({ type: AppAction.setCurrentMultipleLocations, payload: res.data });
				dispatch({ type: AppAction.setMultipleLocationsAnalysed, payload: false });
				dispatch({ type: AppAction.setRightPanel, payload: RightPanel.Multiple });
				dispatch({
					type: AppAction.setSnackBarParams,
					payload: {
						title: "createdLocationSetTitle",
						text: params.name,
						message1: "createdLocationSetMessage1",
						message2: "createdLocationSetMessage2",
					},
				});
				close();
			})
			.catch((error) => {
				console.error(error);
				dispatch({
					type: AppAction.setSnackBarParams,
					payload: {
						title: "errorTitle",
						text: params.name,
						message: "errorMessage",
					},
				});
				close();
			});
	};

	return (
		<Modal
			header={t("locations.createModal.title")}
			opened={context.createModal}
			onClose={close}
		>
			<div className={styles.modal}>
				<Stack sx={{ width: 350 }}>
					<TextField
						required
						id="outlined-required"
						label={t("locations.createModal.text1")}
						value={inputValue}
						onChange={handleChange}
						onBlur={(e) => {
							if (e.target.value) {
								setIsError(false);
							} else {
								setIsError(true);
							}
						}}
						onFocus={() => {
							setIsError(false);
						}}
						color="success"
						error={isError}
						helperText={isError && t("locations.createModal.helperText")}
					/>
					{/* <Autocomplete
						disablePortal
						value={selectValue}
						onChange={(event, newValue) => {
							setSelectValue(newValue);
						}}
						id="controllable-states-demo"
						options={options}
						renderInput={(params) => (
							<TextField
								required
								{...params}
								label={t("locations.createModal.text2")}
							/>
						)}
					/> */}
				</Stack>
			</div>
			<div className={styles.buttons}>
				<Button
					onClick={close}
					type="secondary"
				>
					{t("locations.createModal.cancel")}
				</Button>
				&nbsp;&nbsp;
				<Button
					onClick={loadData}
					disabled={!inputValue}
				>
					{t("locations.createModal.create")}
				</Button>
			</div>
		</Modal>
	);
}

export default CreateModal;
