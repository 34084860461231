type ConfigType = {
	[key: string]: string;
};
export const config: ConfigType = {
	FL_Fluvial_Official: "N",
	"FL_Fluvial_Official.FF Code (Official)": "O",
	"FL_Fluvial_Official.FF Frequency (Official)": "P",
	"FL_Fluvial_Official.FF Protection (Official)": "Q",
	"FL_Fluvial_Official.FF Return Period (Official)": "R",
	"FL_Fluvial_Official.FF Schema (Official)": "S",
	"FL_Fluvial_Official.intensity": "T",
	FL_Fluvial_SwissRe: "U",
	"FL_Fluvial_SwissRe.FF Code (SR)": "V",
	"FL_Fluvial_SwissRe.FF Frequency (SR)": "W",
	"FL_Fluvial_SwissRe.FF Protection (SR)": "X",
	"FL_Fluvial_SwissRe.FF Return Period (SR)": "Y",
	"FL_Fluvial_SwissRe.FF Schema (SR)": "Z",
	"FL_Fluvial_SwissRe.intensity": "AA",
	FL_Surge_SwissRe: "AB",
	"FL_Surge_SwissRe.Surge Return Period": "AC",
	"FL_Surge_SwissRe.intensity": "AD",
	FL_Pluvial_SwissRe: "AE",
	"FL_Pluvial_SwissRe.PF Return Period (SR)": "AF",
	"FL_Pluvial_SwissRe.PF Risk Level (SR)": "AG",
	"FL_Pluvial_SwissRe.intensity": "AH",
	EQ_LocalSoilCondition_Global_SwissRe: "AI",
	"EQ_LocalSoilCondition_Global_SwissRe.EQ Hazard": "AJ",
	"EQ_LocalSoilCondition_Global_SwissRe.EQ MMI": "AK",
	"EQ_LocalSoilCondition_Global_SwissRe.EQ PGA [g]": "AL",
	"EQ_LocalSoilCondition_Global_SwissRe.intensity": "AM",
	EQ_Bedrock_Global_SwissRe: "AN",
	"EQ_Bedrock_Global_SwissRe.EQ Hazard": "AO",
	"EQ_Bedrock_Global_SwissRe.EQ MMI": "AP",
	"EQ_Bedrock_Global_SwissRe.EQ PGA [g]": "AQ",
    "EQ_Bedrock_Global_SwissRe.intensity": "AR",
    EQ_Tsunami_SwissRe: "AS",
    "EQ_Tsunami_SwissRe.Tsunami Return Period": "AT",
    "EQ_Tsunami_SwissRe.intensity": "AU",
    WS_Windspeed_Global_SwissRe: "AV",
    "WS_Windspeed_Global_SwissRe.Storm Hazard": "AW",
    "WS_Windspeed_Global_SwissRe.Storm Peak Gust [m/s]": "AX",
    "WS_Windspeed_Global_SwissRe.intensity": "AY",
    "CS_Hail_Global_SwissRe": "AZ",
    "CS_Hail_Global_SwissRe.Hail Days (>2.5cm) per Year": "BA",
    "CS_Hail_Global_SwissRe.Hail Hazard (current climate)": "BB",
    "CS_Hail_Global_SwissRe.31-set.Hail Hazard": "BC",
    "CS_Hail_Global_SwissRe.31-set.Hail Days (31-year-average)": "BD",
    "CS_Hail_Global_SwissRe.31-set.Hail Days (31 year-average)": "BD",
    "CS_Hail_Global_SwissRe.31-set.31-year trend": "BE",
    "CS_Hail_Global_SwissRe.31-set.Mean Hail Probability (31-year data)": "BF",
    "CS_Hail_Global_SwissRe.64-set.Hail Hazard": "BG",
    "CS_Hail_Global_SwissRe.64-set.Hail Days (64 year-average)": "BH",
    "CS_Hail_Global_SwissRe.64-set.Hail Days (64-year-average)": "BH",
    "CS_Hail_Global_SwissRe.64-set.64-year trend": "BI",
    "CS_Hail_Global_SwissRe.64-set.Mean Hail Probability (64-year data)": "BJ",
    "CS_Hail_Global_SwissRe.intensity": "BK",
    "CS_Tornado_Global_SwissRe": "BL",
    "CS_Tornado_Global_SwissRe.Tornado Hazard": "BM",
    "CS_Tornado_Global_SwissRe.Tornadoes per year": "BN",
    "CS_Tornado_Global_SwissRe.intensity": "BO",
    "EQ_Landslide_Global_SwissRe": "BP",
    "EQ_Landslide_Global_SwissRe.Landslide Hazard": "BQ",
    "EQ_Landslide_Global_SwissRe.intensity": "BR",
    "WF_Wildfire_Global_SwissRe": "BS",
    "WF_Wildfire_Global_SwissRe.Wildfire Hazard": "BT",
    "WF_Wildfire_Global_SwissRe.Wildfire Susceptibility": "BU",
    "WF_Wildfire_Global_SwissRe.intensity": "BV",
    "CS_Lightning_Global_SwissRe": "BW",
    "CS_Lightning_Global_SwissRe.Lightning Hazard": "BX",
    "CS_Lightning_Global_SwissRe.Lightning Annual Flash Rate/km²": "BY",
    "CS_Lightning_Global_SwissRe.intensity": "BZ",
    "VO_AshThickness_Global_SwissRe": "CA",
    "VO_AshThickness_Global_SwissRe.Volcano Hazard": "CB",
    "VO_AshThickness_Global_SwissRe.Volcano Ash Thickness [cm]": "CC",
    "VO_AshThickness_Global_SwissRe.intensity": "CD",
    "WF_DistToBush_AUS_SwissRe": "CE",
    "WF_DistToBush_AUS_SwissRe.Australia: Distance to Bush": "CF",
    "WF_DistToBush_AUS_SwissRe.Distance to Bush (m)": "CG",
    "DR_Subsidence_France_SwissRe": "CH",
    "DR_Subsidence_France_SwissRe.Subsidence Hazard": "CI",
};
