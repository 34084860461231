import styles from "./multipleAgenda.module.scss";
import { AppContext } from "helpers/context";
import { MapContext } from "helpers/map";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { getCounts, getCheckedCounts } from "helpers/helpers";
import { colorByTiv } from "helpers/locations";
import { ReactComponent as MyLocaltionSvg } from "assets/my_localtion.svg";
import * as turf from '@turf/turf';

function MultipleAgenda() {
    const [context, dispatch] = useContext(AppContext);
    const { AMap, map } = useContext(MapContext);
    const [t] = useTranslation();

    const handleClickMyLocation = () => {
        if (context.currentMultipleLocations && context.currentMultipleLocations.items) {
            let bounds: any = [];
            context.currentMultipleLocations.items.map((item: any) => {
                if (item.checked) {
                    bounds.push([item.longitude, item.latitude]);
                }
            });
            if (bounds.length === 0) return
            let bbox: any = turf.bboxPolygon(turf.bbox(turf.multiPoint(bounds)));
            var myBounds = new AMap.Bounds([bbox.bbox[2], bbox.bbox[3]], [bbox.bbox[0], bbox.bbox[1]]);
            console.log(myBounds);
            map?.setBounds(myBounds, true, [20, 20, 20, 20]);
        }
    }

    return (
        <div className={styles.multipleAgenda}>
            <div className={styles.header}>
                <div className={styles.text}>{context.currentMultipleLocations!.name}</div>
                <MyLocaltionSvg
                    className="w-[16px] h-[16px] fill-[#0009] cursor-pointer"
                    onClick={handleClickMyLocation}
                />
            </div>
            <div>
                <div className={styles.subtitle}>({t("legend.showingLocations")} {getCheckedCounts(context.currentMultipleLocations!.items)}/{context.currentMultipleLocations?.itemCount})</div>
                <div className={styles.legend}>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <span
                            style={{
                                backgroundColor: "green",//colorByTiv(0),
                                opacity: '1',
                            }}
                        ></span>
                        100%({context.currentMultipleLocations?.itemCount})
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MultipleAgenda;
