import styles from "./agenda.module.scss";
import { LegendData } from "helpers/models";
import { useTranslation } from "react-i18next";

interface Props extends LegendData {
	onClose?: () => void;
}

interface legendColor {
	raster: {
		color?: string;
		opacity?: number;
	};
	polygon: {
		fill?: string;
		fillOpacity?: number;
		stroke?: string;
		strokeDashArray?: any;
		strokeOpacity?: number;
	};
}

interface legendItem {
	title: string;
	symbolizers: legendColor[];
}

function Agenda(props: Props) {
	const [t] = useTranslation();
	return (
		<div className={styles.agenda}>
			<div className={styles.header}>
				<div className={styles.text}>{t("legend." + props.title)}</div>
				<div
					role="img"
					className={`material-icons ${styles.icon}`}
					aria-hidden="true"
					data-mat-icon-type="font"
					onClick={props.onClose}
				>
					cancel
				</div>
			</div>
			<div>
				{props.subtitle && <div className={styles.subtitle}>{t("legend." + props.subtitle)}</div>}
				<div className={styles.legend}>
					{props.rules &&
						props.rules.map((item: legendItem, index: number) => (
							<div
								key={index}
								style={{
									display: item.symbolizers[0].raster
										? item.symbolizers[0].raster.opacity === 0
											? "none"
											: "flex"
										: item.symbolizers[0].polygon.fillOpacity === 0
											? "none"
											: "flex",
									alignItems: "center",
								}}
							>
								<span
									style={{
										backgroundColor: item.symbolizers[0].raster ? item.symbolizers[0].raster.color : item.symbolizers[0].polygon.fill,
										opacity: item.symbolizers[0].raster ? item.symbolizers[0].raster.opacity : item.symbolizers[0].polygon.fillOpacity,
									}}
								></span>
								{t("legend." + item.title)}
							</div>
						))}
				</div>
			</div>
		</div>
	);
}

export default Agenda;
