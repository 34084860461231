import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { addLocationsets, batchGeo, getLocationsetsItems } from "api/layer";
import countryArray from "assets/json/country.json";
import Button from "components/button/button";
import Modal from "components/modal/modal";
import { AppAction, AppContext, getMultipleLocations } from "helpers/context";
import { getLanguage } from "helpers/translations";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./edit-modal.module.scss";
import { pollingFunction } from "./polling";

function EditModal() {
	const [context, dispatch] = useContext(AppContext);
	const [t] = useTranslation();
	const [isError, setIsError] = useState(false);
	const currentMultipleLocations = context.currentMultipleLocations;
	const [inputValue, setInputValue] = useState(currentMultipleLocations?.name || "");

	const locationSource = context.locationSource;
	const language = getLanguage();

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.value) {
			setIsError(false);
		} else {
			setIsError(true);
		}
		setInputValue(e.target.value);
	};

	const close = (): void => {
		dispatch({ type: AppAction.setEditModal, payload: undefined });
	};

	const save = () => {
		if (!inputValue) {
			return;
		}
		if (!currentMultipleLocations) {
			return;
		}

		const param = {
			name: inputValue,
			items: currentMultipleLocations.items,
		};

		dispatch({ type: AppAction.setCurrentMultipleLocations, payload: param });

		if (locationSource === "latlng") {
			addLocationsets(param).then(async (result: any) => {
				if (result.code && result.code === 400) {
					dispatch({
						type: AppAction.setSnackBarParams,
						payload: {
							title: "tip",
							titleIconColor: "red",
							titleIcon: "error",
							resMessage: getLanguage() === "en" ? result.data.messageEN : result.data.messageCN,
						},
					});

					dispatch({ type: AppAction.setSingleLocationAnalysed, payload: false });
					dispatch({ type: AppAction.setMultipleLocationsAnalysed, payload: false });
                    close();
					return;
				}
				if (result.status) {
					const locationSetId = result.data.id;
					console.log(locationSetId);
					getLocationsetsItems(locationSetId).then(async (res) => {
						dispatch({ type: AppAction.setCurrentMultipleLocations, payload: res.data });
						getMultipleLocations(dispatch).then(() => {
							dispatch({ type: AppAction.setSingleLocation, payload: undefined });
							dispatch({ type: AppAction.setSingleLocationAnalysed, payload: undefined });
							close();
						});
					});
				}
			});
		} else if (locationSource === "address") {
			dispatch({ type: AppAction.setAddressEncoding, payload: true });

			close();

			dispatch({ type: AppAction.setAddressEncodingLoadingText, payload: t("addressEncoding.progress.start") });

			const locationData =
				currentMultipleLocations &&
				currentMultipleLocations.items &&
				currentMultipleLocations?.items.map((item) => {
					const { id, number, place, street, city, province, country, name } = item;

					let address = item.address;
					if (!address) {
						// const addressEn = `${name || ""} ${number || ""} ${street || ""} ${country || ""} ${place || ""} ${province || ""}`;
						const addressEn = `${number || ""} ${street || ""} ${place || ""} ${province || ""} ${country || ""}`;

						// const addressCn = `${province || ""}  ${place || ""} ${country || ""} ${street || ""} ${number || ""} ${name || ""}`;
						const addressCn = `${country || ""} ${province || ""}  ${place || ""} ${street || ""} ${number || ""}`;

						address = (language === "en" ? addressEn : addressCn).replace("undefined", "");
					}

					const countryObj = countryArray.find((e) => e.label === country);

					console.log(countryObj);

					return {
						locationId: id,
						address,
						language,
						country: countryObj?.value,
					};
				});

			if (!locationData || locationData.length === 0) {
				return;
			}

			dispatch({ type: AppAction.setAddressEncodingLoading, payload: true });

			batchGeo({
				addressList: locationData,
			}).then((res: any) => {
				console.log(res);
				if (!res || !res.code || res.code !== 200) {
					return;
				}

				const batchId = res.data.batchId;

				if (!batchId) {
					return;
				}

				dispatch({ type: AppAction.setAddressEncodingLoadingText, payload: t("addressEncoding.progress.queue") });

				pollingFunction(3000, batchId, dispatch);
			});
		}
	};

	useEffect(() => {
		setInputValue(context.currentMultipleLocations?.name || "");
	}, [context]);

	return (
		<Modal
			header={t("locations.editModal.title")}
			opened={!!context.editModal}
			onClose={close}
		>
			<div className={styles.modal}>
				<Stack
					spacing={3}
					sx={{ width: 300 }}
				>
					<TextField
						required
						id="outlined-required"
						label={t("locations.editModal.name")}
						// defaultValue={currentMultipleLocations?.name}
						value={inputValue}
						onChange={handleChange}
						onBlur={(e) => {
							if (e.target.value) {
								setIsError(false);
							} else {
								setIsError(true);
							}
						}}
						onFocus={() => {
							setIsError(false);
						}}
						// onChange={(e) => setInputValue(e.target.value)}
						color="success"
						error={isError}
						helperText={isError && t("locations.editModal.nameText")}
					/>
				</Stack>
			</div>
			<div className={styles.buttons}>
				<Button
					onClick={close}
					type="secondary"
				>
					{t("locations.editModal.cancel")}
				</Button>
				&nbsp;&nbsp;
				<Button onClick={save}>{t("locations.editModal.create")}</Button>
			</div>
		</Modal>
	);
}

export default EditModal;
