import React, { Dispatch, Reducer } from "react";
import { LeftPanel, RightPanel, zoomLevelDefault } from "./constants";
import { LocationData, MapData, LegendData, MapLayer, Children, AnalysisItem, LegendItem, AnalysisData, crsLayer, ClimateItems } from "./models";
import { getLanguage } from "./translations";
import { getLocationsets, getLocationsetsItems } from "api/layer";

export interface IAppArguments {
	type: AppAction;
	payload?:
	| string
	| LeftPanel
	| RightPanel
	| MapData
	| boolean
	| number[]
	| number
	| Children
	| LocationData[]
	| LocationData
	| LegendData[]
	| MapLayer[]
	| crsLayer[]
	| AnalysisItem[]
	| ClimateItems[]
	| LegendItem[]
	| AnalysisData
	| any;
}

export enum AppAction {
	setMenu = "SET_MENU",
	setLanguage = "SET_LANGUAGE",
	setLeftPanel = "SET_LEFT_PANEL",
	setRightPanel = "SET_RIGHT_PANEL",
	setMapData = "SET_MAP_DATA",
	setMapCenter = "SET_MAP_CENTER",
	setDeleteLocationSetModal = "SET_DELETE_LOCATION_SET_MODAL",
	setDeleteLocationModal = "SET_DELETE_LOCATION_MODAL",
	setSearchMapCenter = "SET_SEARCH_MAP_CENTER",
	setMapZoom = "SET_MAP_ZOOM",
	setAddressEncoding = "SET_ADDRESS_ENCODING",
	setAddressEncodingLoading = "SET_ADDRESS_ENCODING_LOADING",
	setAddressEncodingLoadingText = "SET_ADDRESS_ENCODING_LOADING_TEXT",
	setAddressEncodingData = "SET_ADDRESS_ENCODING_DATA",
	setAddressData = "SET_ADDRESS_DATA",
	setLayerFloods = "SET_LAYER_FLOODS",
	setLayerEarthquakes = "SET_LAYER_EARTHQUAKES",
	setAnalyseModal = "SET_ANALYSE_MODAL",
	setImportModal = "SET_IMPORT_MODAL",
	setErrorModal = "SET_ERROR_MODAL",
	setErrorData = "SET_ERROR_DATA",
	setShowLocationAnalysisDetails = "SET_SHOW_LOCATION_ANALYSIS_DETAILS",
	setImportMenu = "SET_IMPORT_MENU",
	setCreateModal = "SET_CREATE_MODAL",
	setEditModal = "SET_EDIT_MODAL",
	setLayerInfoModal = "SET_LAYER_INFO_MODAL",
	setTipModal = "SET_TIP_MODAL",
	setClimateRiskModal = "SET_CLIMATE_RISK_MODAL",
	setSingleLocation = "SET_SINGLE_LOCATION",
	setSingleLocationAnalysed = "SET_SINGLE_LOCATION_ANALYSED",
	setMultipleLocations = "SET_MULTIPLE_LOCATIONS",
	setMultipleLocationsLoadings = "SET_MULTIPLE_LOCATIONS_LOADINGS",
	setMultipleLocationsSetLoadings = "SET_MULTIPLE_LOCATIONS_SET_LOADINGS",
	setMultipleLocationsEdit = "SET_MULTIPLE_LOCATIONS_EDIT",
	setMultipleLocationsAnalysed = "SET_MULTIPLE_LOCATIONS_ANALYSED",
	setMultipleLocationsSetId = "SET_MULTIPLE_LOCATIONS_SET_ID",
	setMultipleAnalysedLocations = "SET_MULTIPLE_ANALYSED_LOCATIONS",
	setCurrentMultipleLocations = "SET_CURRENT_MULTIPLE_LOCATIONS",
	setCurrentMultipleLocationInfo = "SET_CURRENT_MULTIPLE_LOCATION_INFO",
	setEditMultipleLocation = "SET_EDIT_MULTIPLE_LOCATION",
	setLayerList = "SET_LYAER_LIST",
	updateLayerList = "UPDATE_LAYER_LIST",
	setCrsLayerList = "SET_CRS_LYAER_LIST",
	setLayerLegend = "SET_LYAER_LEGEND",
	setSearchLocationInfo = "SET_SEARCH_LOCATION_INFO",
	setAnalysisLegends = "SET_ANALYSIS_LEGENDS",
	setAnalysisResults = "SET_ANALYSIS_RESULTS",
	setAnalysisData = "SET_ANALYSIS_DATA",
	addLayerLegend = "ADD_LAYER_LEGEND",
	deleteLayerLegend = "DELETE_LAYER_LEGEND",
	updateLayerLegend = "UPDATE_LAYER_LEGEND",
	setMapClickState = "SET_MAP_CLICK_STATE",
	setBatchId = "SET_BATCH_ID",
	setOption = "SET_OPTION",
	setLocationsSize = "SET_LOCATIONS_SIZE",
	setLocationSource = "SET_LOCATION_SOURCE",
	setJobId = "SET_JOB_ID",
	setHazardLegends = "SET_HAZARD_LEGENDS",
	setHazardMultipleAnalysisResult = "SET_HAZARD_MULTIPLE_ANALYSIS_RESULT",
	setClimateMultipleAnalysisResult = "SET_CLIMATE_MULTIPLE_ANALYSIS_RESULT",
	setHazardMultipleAnalysedLegends = "SET_HAZARD_MULTIPLE_ANALYSED_LEGENDS",
	setClimateMultipleAnalysedLegends = "SET_CLIMATE_MULTIPLE_ANALYSED_LEGENDS",
	setHazardMultipleAnalysisStatus = "SET_HAZARD_MULTIPLE_ANALYSIS_STATUS",
	setClimateMultipleAnalysisStatus = "SET_CLIMATE_MULTIPLE_ANALYSIS_STATUS",
	setSnackBarParams = "SET_SNACK_BAR_Params",
	setArrowLeft = "SET_ARROW_LEFT",
	setArrowRight = "SET_ARROW_RIGHT",
	setUserRoles = "SET_USER_ROLES",
}

export interface AppState {
	menu: any[];
	language: string;
	leftPanel: LeftPanel;
	rightPanel: RightPanel;
	mapData: MapData;
	mapCenter?: number[];
	searchMapCenter?: number[];
	mapZoom: number;
	layerFloods: boolean;
	layerEarthquakes: boolean;
	analyseModal?: "1" | "2" | undefined;
	importModal?: boolean;
	errorModal?: boolean;
	errorData?: { row: number; message: string }[];
	showLocationAnalysisDetails?: boolean;
	importMenu: boolean;
	createModal?: boolean;
	editModal?: boolean;
	layerInfoModal?: Children;
	tipModal?: any;
	climateRiskModal?: boolean;
	singleLocation?: LocationData;
	singleLocationAnalysed?: boolean;
	multipleLocations?: LocationData[];
	multipleLocationsLoadings?: boolean;
	multipleLocationsSetLoadings?: boolean;
	multipleLocationsEdit?: boolean;
	multipleLocationsAnalysed?: boolean;
	multipleLocationsSetId?: string;
	currentMultipleLocations?: LocationData;
	currentMultipleLocationInfo?: any;
	editMultipleLocation?: any;
	vectorMapForeign?: string;
	layerList?: MapLayer[];
	layerLegend?: LegendData[];
	crsLayerList?: crsLayer[];
	searchLocationInfo?: any;
	analysisResults?: AnalysisItem[];
	analysisLegends?: LegendItem[];
	analysisData?: AnalysisData;
	mapClickState?: boolean;
	batchId?: string;
	option?: string;
	addressEncoding?: boolean;
	addressEncodingLoading?: boolean;
	addressEncodingLoadingText?: string;
	addressEncodingData?: any[];
	addressData?: any[];
	locationsSize?: boolean;
	locationSource?: string;
	jobId?: string;
	hazardLegends?: LegendItem[];
	hazardMultipleAnalysisResult?: AnalysisItem[];
	climateMultipleAnalysisResult?: ClimateItems[];
	hazardMultipleAnalysedLegends?: LegendItem[];
	climateMultipleAnalysedLegends?: crsLayer[];
	multipleAnalysedLocations?: any[];
	hazardMultipleAnalysisStatus?: string;
	climateMultipleAnalysisStatus?: string;
	deleteLocationSetModal?: any;
	deleteLocationModal?: any;
	snackBarParams?: {
		title?: any;
		title1?: any;
		title2?: any;
		titleIcon?: any;
		message?: any;
		message1?: any;
		message2?: any;
		text?: any;
		titleIconColor?: any;
		resMessage?: any;
	};
	arrowLeft?: boolean;
	arrowRight?: boolean;
	userRoles?: string[];
}

export const appInitialState: AppState = {
	menu: [],
	language: getLanguage() || "",
	leftPanel: LeftPanel.Empty,
	rightPanel: RightPanel.Empty,
	mapData: { zoom: 0, centerLat: 0, centerLon: 0 },
	mapZoom: zoomLevelDefault,
	layerFloods: false,
	layerEarthquakes: false,
	importMenu: false,
	multipleLocationsLoadings: true,
	vectorMapForeign: "2D",
	option: "hazard",
};

export const appContextReducer: Reducer<AppState, IAppArguments> = (state: AppState = appInitialState, action: IAppArguments): AppState => {
	switch (action.type) {
		case AppAction.setMenu:
			return {
				...state,
				menu: action.payload as any[],
			};
		case AppAction.setAddressEncodingData:
			return {
				...state,
				addressEncodingData: action.payload as any[],
			};
		case AppAction.setAddressEncodingLoadingText:
			return {
				...state,
				addressEncodingLoadingText: action.payload as string,
			};
		case AppAction.setAddressEncoding:
			return {
				...state,
				addressEncoding: action.payload as boolean,
			};
		case AppAction.setAddressEncodingLoading:
			return {
				...state,
				addressEncodingLoading: action.payload as boolean,
			};
		case AppAction.setLanguage:
			return {
				...state,
				language: action.payload as string,
			};
		case AppAction.setLeftPanel:
			return {
				...state,
				leftPanel: action.payload as LeftPanel,
			};
		case AppAction.setRightPanel:
			return {
				...state,
				rightPanel: action.payload as RightPanel,
			};
		case AppAction.setMapData:
			return {
				...state,
				mapData: action.payload as MapData,
			};
		case AppAction.setMapCenter:
			return {
				...state,
				mapCenter: action.payload as number[],
			};
		case AppAction.setSearchMapCenter:
			return {
				...state,
				searchMapCenter: action.payload as number[],
			};
		case AppAction.setMapZoom:
			return {
				...state,
				mapZoom: action.payload as number,
			};
		case AppAction.setLayerFloods:
			return {
				...state,
				layerFloods: action.payload as boolean,
			};
		case AppAction.setLayerEarthquakes:
			return {
				...state,
				layerEarthquakes: action.payload as boolean,
			};
		case AppAction.setAnalyseModal:
			return {
				...state,
				analyseModal: action.payload as "1" | "2" | undefined,
			};
		case AppAction.setImportModal:
			return {
				...state,
				importModal: action.payload as boolean,
			};
		case AppAction.setErrorModal:
			return {
				...state,
				errorModal: action.payload as boolean,
			};
		case AppAction.setErrorData:
			return {
				...state,
				errorData: action.payload as { row: number; message: string }[],
			};
		case AppAction.setShowLocationAnalysisDetails:
			return {
				...state,
				showLocationAnalysisDetails: action.payload as boolean,
			};
		case AppAction.setImportMenu:
			return {
				...state,
				importMenu: action.payload as boolean,
			};
		case AppAction.setCreateModal:
			return {
				...state,
				createModal: action.payload as boolean,
			};
		case AppAction.setEditModal:
			return {
				...state,
				editModal: action.payload as boolean,
			};
		case AppAction.setLayerInfoModal:
			return {
				...state,
				layerInfoModal: action.payload as Children,
			};
		case AppAction.setTipModal:
			return {
				...state,
				tipModal: action.payload as any,
			};
		case AppAction.setClimateRiskModal:
			return {
				...state,
				climateRiskModal: action.payload as boolean,
			};
		case AppAction.setSingleLocation:
			return {
				...state,
				singleLocation: action.payload as LocationData,
			};
		case AppAction.setSingleLocationAnalysed:
			return {
				...state,
				singleLocationAnalysed: action.payload as boolean,
			};
		case AppAction.setMultipleLocations:
			return {
				...state,
				multipleLocations: action.payload as LocationData[],
			};
		case AppAction.setMultipleLocationsLoadings:
			return {
				...state,
				multipleLocationsLoadings: action.payload as boolean,
			};
		case AppAction.setMultipleLocationsSetLoadings:
			return {
				...state,
				multipleLocationsSetLoadings: action.payload as boolean,
			};
		case AppAction.setAddressData:
			return {
				...state,
				addressData: action.payload as any[],
			};
		case AppAction.setMultipleLocationsEdit:
			return {
				...state,
				multipleLocationsEdit: action.payload as boolean,
			};
		case AppAction.setMultipleLocationsAnalysed:
			return {
				...state,
				multipleLocationsAnalysed: action.payload as boolean,
			};
		case AppAction.setMultipleLocationsSetId:
			return {
				...state,
				multipleLocationsSetId: action.payload as string,
			};
		case AppAction.setCurrentMultipleLocations:
			return {
				...state,
				currentMultipleLocations: action.payload as LocationData,
			};
		case AppAction.setCurrentMultipleLocationInfo:
			return {
				...state,
				currentMultipleLocationInfo: action.payload as any,
			};
		case AppAction.setEditMultipleLocation:
			return {
				...state,
				editMultipleLocation: action.payload as any,
			};
		case AppAction.setLayerList:
			return {
				...state,
				layerList: action.payload as MapLayer[],
			};
		case AppAction.updateLayerList:
			if (state.layerList) {
				state.layerList = state.layerList.map((layer) => {
					if (layer.name === action.payload.group) {
						layer.children.map((layerItem) => {
							if (layerItem.id === action.payload.id) {
								layerItem = action.payload
								console.log(layerItem);
							}
							
							return layerItem;
						})
					}
					return layer;
				});
			}
			return {
				...state,
			};
		case AppAction.setLayerLegend:
			return {
				...state,
				layerLegend: action.payload as LegendData[],
			};
		case AppAction.setCrsLayerList:
			return {
				...state,
				crsLayerList: action.payload as crsLayer[],
			};
		case AppAction.addLayerLegend:
			if (state.layerLegend === undefined) {
				state.layerLegend = [action.payload as LegendData];
			} else {
				state.layerLegend.push(action.payload as LegendData);
			}
			return {
				...state,
			};
		case AppAction.deleteLayerLegend:
			if (state.layerLegend === undefined) {
				state.layerLegend = [];
			} else {
				state.layerLegend = state.layerLegend.filter((legend) => legend.layerId !== action.payload.id);
			}
			return {
				...state,
			};
		case AppAction.updateLayerLegend:
			if (state.layerLegend) {
				state.layerLegend = state.layerLegend.map((legend) => {
					if (legend.layerId === action.payload.id) {
						legend.layerInfo = action.payload;
					}
					return legend;
				});
			}
			return {
				...state,
			};
		case AppAction.setSearchLocationInfo:
			return {
				...state,
				searchLocationInfo: action.payload,
			};
		case AppAction.setAnalysisResults:
			return {
				...state,
				analysisResults: action.payload as AnalysisItem[],
			};
		case AppAction.setAnalysisLegends:
			return {
				...state,
				analysisLegends: action.payload as LegendItem[],
			};
		case AppAction.setAnalysisData:
			return {
				...state,
				analysisData: action.payload as AnalysisData,
			};
		case AppAction.setMapClickState:
			return {
				...state,
				mapClickState: action.payload as boolean,
			};
		case AppAction.setBatchId:
			return {
				...state,
				batchId: action.payload as string,
			};
		case AppAction.setOption:
			return {
				...state,
				option: action.payload as string,
			};
		case AppAction.setLocationsSize:
			return {
				...state,
				locationsSize: action.payload as boolean,
			};
		case AppAction.setLocationSource:
			return {
				...state,
				locationSource: action.payload as string,
			};
		case AppAction.setJobId:
			return {
				...state,
				jobId: action.payload as string,
			};
		case AppAction.setHazardLegends:
			return {
				...state,
				hazardLegends: action.payload as LegendItem[],
			};
		case AppAction.setHazardMultipleAnalysisResult:
			return {
				...state,
				hazardMultipleAnalysisResult: action.payload as AnalysisItem[],
			};
		case AppAction.setClimateMultipleAnalysisResult:
			return {
				...state,
				climateMultipleAnalysisResult: action.payload as ClimateItems[],
			};
		case AppAction.setHazardMultipleAnalysedLegends:
			return {
				...state,
				hazardMultipleAnalysedLegends: action.payload as LegendItem[],
			};
		case AppAction.setClimateMultipleAnalysedLegends:
			return {
				...state,
				climateMultipleAnalysedLegends: action.payload as crsLayer[],
			};
		case AppAction.setMultipleAnalysedLocations:
			return {
				...state,
				multipleAnalysedLocations: action.payload as any[],
			};
		case AppAction.setHazardMultipleAnalysisStatus:
			return {
				...state,
				hazardMultipleAnalysisStatus: action.payload as string,
			};
		case AppAction.setClimateMultipleAnalysisStatus:
			return {
				...state,
				climateMultipleAnalysisStatus: action.payload as string,
			};
		case AppAction.setDeleteLocationSetModal:
			return {
				...state,
				deleteLocationSetModal: action.payload as any,
			};
		case AppAction.setDeleteLocationModal:
			return {
				...state,
				deleteLocationModal: action.payload as any,
			};
		case AppAction.setSnackBarParams:
			return {
				...state,
				snackBarParams: action.payload as any,
			};
		case AppAction.setArrowLeft:
			return {
				...state,
				arrowLeft: action.payload as boolean,
			};
		case AppAction.setArrowRight:
			return {
				...state,
				arrowRight: action.payload as boolean,
			};
		case AppAction.setUserRoles:
			return {
				...state,
				userRoles: action.payload as string[],
			};
		default:
			return state;
	}
};

export const AppContext = React.createContext<[AppState, Dispatch<IAppArguments>]>([appInitialState, (action: IAppArguments) => appContextReducer(appInitialState, action)]);

export const setLeftPanel = (dispatch: React.Dispatch<IAppArguments>, leftPanel: LeftPanel): void => {
	dispatch({ type: AppAction.setLeftPanel, payload: leftPanel });
};

export const getMultipleLocations = async (dispatch: React.Dispatch<IAppArguments>): Promise<any> => {
	dispatch({ type: AppAction.setMultipleLocationsLoadings, payload: true });
	const res = await getLocationsets();
	dispatch({ type: AppAction.setMultipleLocations, payload: res.data });
	dispatch({ type: AppAction.setMultipleLocationsLoadings, payload: false });
	return res;
};
